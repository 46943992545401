<template>
  <div class="container-layout-custom justify-center full-height ak-text light-theme-text-default" style="max-width: 1980px;">
    <div class="q-pa-xl full-width">
      <div class="row q-mb-md justify-between">
        <div class="text-h4">Custom Themes</div>
      </div>
      <div class="row full-width">
        <div v-if="$store.getters['themes/isPreviewing'] || showForm" class="row full-width q-mb-md">
          <q-btn @click="showModal = true" color="primary">Save Theme</q-btn>
          <q-btn @click="resetPage" flat class="q-mx-lg">Cancel</q-btn>
          <q-btn v-if="theme.uid" @click="deleteTheme" flat class="q-mx-lg">Delete</q-btn>
          <div class="row col-12 q-my-md">
            Navigate the site to see how your theme looks in action
          </div>
        </div>
        <div v-else class="q-mb-md row full-width items-center">
          <q-select v-model="selectedTheme" :options="$store.getters['themes/getCustomThemes']" option-value="uid" @input="selectTheme" label="Select Theme to Edit" class="col-4">
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-italic text-grey">
                  You don't have any custom themes yet
                </q-item-section>
              </q-item>
            </template>
          </q-select>
          <div class="q-mx-xl">OR</div>
          <q-btn color="primary" label="Create New Theme" @click="showForm = true" />
        </div>

        <div v-if="$store.getters['themes/isPreviewing'] || showForm">
          <div v-for="(section, i) in sections" :key="i" class="row full-width">
            <q-separator class="full-width"/>
            <div class="row full-width">
              <div class="row">
                <div class="text-h6">{{ section.name }}</div>
              </div>
            </div>
            <div v-for="(property, j) in section.properties" :key="j" class="row full-width q-my-lg items-center">
              <q-input v-model="theme.colors[property.value]" :label="property.label" @input="updateColor(property.value)" class="q-mr-xl">
                <template v-slot:append>
                  <q-icon name="colorize" class="cursor-pointer">
                    <q-popup-proxy transition-show="scale" transition-hide="scale">
                      <q-color v-model="theme.colors[property.value]" no-header @change="updateColor(property.value)" />
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
              <div v-if="property.hasPreviewText" :class="property.value + '-text'">
                I am an example of {{ property.label }}
              </div>
              <div v-if="property.value === 'primary'">
                <q-btn :color="property.value" class="q-mx-sm">Button</q-btn>
                <q-btn :color="property.value" outline class="q-mx-sm">Button</q-btn>
                <q-btn :color="property.value" flat class="q-mx-sm">Button</q-btn>
              </div>
              <div v-if="property.hasBanner">
                <q-banner :color="property.value" :class="'bg-' + property.value" class="text-white text-center q-mx-sm" rounded style="width: 300px">
                  <template v-slot:avatar>
                    <q-icon :name="property.icon" color="white" />
                  </template>
                  {{ property.label }}
                </q-banner>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <q-dialog v-model="showModal">
      <q-card style="width: 560px">
        <q-form @submit="theme.uid ? updateTheme() : addTheme()" @reset="showModal = false" autocomplete="off">
          <q-card-section>
            <div class="text-h5">Publish Theme</div>
          </q-card-section>
          <q-card-section>
            <q-input v-model="theme.label" label="Theme Name"/>
          </q-card-section>
          <div class="row gutter-xs q-my-lg q-mx-lg justify-end">
            <q-btn type="reset" flat >Cancel</q-btn>
            <q-btn type="submit" color="primary" :loading="saving">{{ this.theme.uid ? 'Update' : 'Publish' }}</q-btn>
          </div>
        </q-form>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { colors } from 'quasar'
const { setBrand, getBrand } = colors

export default {
  data () {
    return {
      showForm: false,
      saving: false,
      themeName: '',
      showModal: false,
      selectedTheme: null,
      theme: {
        uid: null,
        label: null,
        colors: {}
      },
      newTheme: {},
      userHasUpdatedColor: false,
      sections: [
        {
          name: 'Layout',
          properties: [
            { label: 'Header', value: 'lmsHeader' },
            { label: 'Header Search Bar', value: 'lmsSearchBar' },
            { label: 'Side Menu Background', value: 'lmsSidebar' },
            { label: 'Background', value: 'lmsBackground' }
          ]
        },
        {
          name: 'Text',
          properties: [
            { label: 'Primary Text', value: 'primaryText', hasPreviewText: true },
            { label: 'Secondary Text', value: 'secondaryText', hasPreviewText: true }
          ]
        },
        {
          name: 'Base',
          properties: [
            { label: 'Primary', value: 'primary' },
            { label: 'Positive', value: 'positive', hasBanner: true, icon: 'thumb_up' },
            { label: 'Negative', value: 'negative', hasBanner: true, icon: 'thumb_down' },
            { label: 'Info', value: 'info', hasBanner: true, icon: 'info' },
            { label: 'Warning', value: 'warning', hasBanner: true, icon: 'warning' }
          ]
        }
      ]
    }
  },
  methods: {
    selectTheme () {
      this.showForm = true
      this.theme = JSON.parse(JSON.stringify(this.selectedTheme))
      if (!this.$store.getters['themes/isPreviewing']) {
        this.$store.dispatch('themes/setPreviewingTheme', { previewingTheme: true, theme: this.theme })
      }
      Object.entries(this.theme.colors).forEach(color => {
        setBrand(color[0], color[1])
      })
    },
    updateColor (name) {
      this.$store.dispatch('themes/setPreviewingTheme', { previewingTheme: true, theme: this.theme })
      setBrand(name, this.theme.colors[name])
    },
    setNewTheme () {
      this.theme = {
        uid: null,
        label: '',
        colors: {
          primary: getBrand('primary'),
          positive: getBrand('positive'),
          negative: getBrand('negative'),
          info: getBrand('info'),
          warning: getBrand('warning'),
          lmsHeader: getBrand('lmsHeader'),
          lmsSearchBar: getBrand('lmsSearchBar'),
          lmsSidebar: getBrand('lmsSidebar'),
          lmsBackground: getBrand('lmsBackground'),
          primaryText: getBrand('primaryText'),
          secondaryText: getBrand('secondaryText')
        }
      }
    },
    addTheme () {
      this.saving = true
      this.$store.dispatch('themes/add', this.theme).then(() => {
        this.$successMsg()
        this.resetPage()
      }).catch((e) => {
        this.$failureMsg()
        this.saving = false
      })
    },
    updateTheme () {
      this.saving = true
      this.$q.loading.show()
      this.$store.dispatch('themes/update', this.theme).then(() => {
        this.$successMsg()
        this.$q.loading.hide()
        this.resetPage()
      }).catch((e) => {
        this.$failureMsg()
        this.$q.loading.hide()
        this.resetPage()
      })
    },
    deleteTheme () {
      this.$q.dialog({
        title: 'Confirm Delete',
        message: `Are you sure you want to permantently delete the theme '${this.theme.label}'?`,
        ok: 'Delete',
        cancel: 'Cancel'
      }).onOk(() => {
        this.$q.loading.show()
        this.$store.dispatch('themes/remove', this.theme).then(() => {
          this.$successMsg()
          this.$q.loading.hide()
          this.resetPage()
        }).catch((e) => {
          this.$failureMsg()
          this.$q.loading.hide()
          this.resetPage()
        })
      })
    },
    resetPage () {
      this.setNewTheme()
      this.saving = false
      this.showModal = false
      this.showForm = false
      this.$store.dispatch('themes/setPreviewingTheme', { previewingTheme: false, theme: {} })
      this.$setTheme(this.$store.getters['auth/currentTheme'])
      this.selectedTheme = null
    }
  },
  created () {
    this.setNewTheme()
    if (this.$store.getters['themes/isPreviewing']) {
      this.theme = this.$store.getters['themes/themePreview']
      this.$setTheme(this.$store.getters['themes/themePreview'])
    }
  }
}
</script>
